$color-alert: #fc3737;
$color-blue-text: #0b0656;
$color-primary-gradient: #000000;
$color-card-membership: linear-gradient(90deg, #86714D -20.25%, #B69C69 12.8%, #ECD697 50.63%, #C6AB70 85.66%, #87714C 117.72%);

$color-primary: #0061AA;
$color-secondary: #161616;

$color-tertiary: #D43B1F;
$button-disabled: #bcbcbc;
$button-success: #5fd997;
$button-warning: #ffcf61;
$button-danger: #ff475d;
